import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getPrefixApi } from './utils';
import { getCode } from './getCode';
import { DEFAULT_APP, SUPPORTED_APPS } from '../constants';

export interface APISiteNameResponse {
  title: string;
  url: string;
  metaSiteId: string;
  preferredApp: string;
}

export const getSiteName = async (flowApi: PlatformControllerFlowAPI) => {
  try {
    const codeData = await getCode(flowApi);
    const prefix = getPrefixApi(flowApi);
    const { data } = await flowApi.httpClient.get<APISiteNameResponse>(
      `${prefix}/clubs/api/v1/invites/details`,
      {
        headers: {
          Authorization : flowApi.controllerConfig.appParams.instance,
        },
        params: {
          code: codeData.code,
        },
      },
    );
    const preferredApp = SUPPORTED_APPS.includes(data.preferredApp) ? data.preferredApp : DEFAULT_APP;

    return {...data, preferredApp};
  } catch (err) {
    return { title: '', url: '' , metaSiteId: '', preferredApp: DEFAULT_APP};
  }
};

export default getSiteName;
