import { EditorScriptFlowAPI, FlowEditorSDK,useEditorSdk } from '@wix/yoshi-flow-editor';
import { EditorReadyOptions } from '@wix/platform-editor-sdk';
import { openShowHidePanel } from '../../panels/showHidePanel/showHideActions';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import { APICodeResponse } from '../../api';
import { CreatePanelApiOptions } from '@wix/blocks-widget-services/dist/types/panels/types';

export const onClickComponentGFPP = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  options: EditorReadyOptions,
  event,
) => {
  const { id, componentRef } = event.detail;
  const panelApiFactory = new PanelsApiFactory();

  const panelsApi = await panelApiFactory.createPanelsApi({
    editorSDK,
    editorType: options.origin.type,
  });

  switch (id) {
    case 'openButtonwithoutLinkPanel': {
      editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
        'token',
        'settings',
        {
          componentRef,
          configuration: {
            controlGroups: {},
            controls: {
              link: { hidden: true },
              label: { hidden: true },
              icon: { hidden: false },
            },
            states: {},
          },
        },
      );
      break;
    }
    case 'openTitlePanelSettings': {
      editorSDK.editor.openNativeComponentPanel<'WRichText'>(
        'token',
        'settings',
        {
          componentRef,
          configuration: {
            controls: {
              link: { hidden: true },
              alignment: { hidden: false },
              bulletsAndNumbering: { hidden: false },
            },
            controlGroups: {
              seoA11y: { hidden: false },
              verticalText: { hidden: true },
            },
            states: {},
          },
        },
      );
      break;
    }
    case 'openDescriptionSettingsPanel': {
      editorSDK.editor.openNativeComponentPanel<'WRichText'>(
        'token',
        'settings',
        {
          componentRef,
          configuration: {
            controls: {
              link: { hidden: true },
              alignment: { hidden: false },
              bulletsAndNumbering: { hidden: false },
            },
            controlGroups: {
              seoA11y: { hidden: false },
              verticalText: { hidden: true },
            },
            states: {},
          },
        },
      );
      break;
    }
    case 'openSiteNameSettings': {
      await panelsApi.openBlocksPanel('siteName2', componentRef);
      break;
    }
    case 'editTitle': {
      await panelsApi.openBlocksPanel('editTitlePanel', componentRef, {
        overrides: {
          title: flowAPI.translations.t('download_my_app.panel.title.title'),
        },
      });
      break;
    }
    case 'editDescription': {
      await panelsApi.openBlocksPanel('editDescPanel', componentRef, {
        overrides: {
          title: flowAPI.translations.t(
            'download_my_app.panel.description.title',
          ),
        },
      });
      break;
    }
  }
};
