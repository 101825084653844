import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { EditorReadyOptions } from '@wix/platform-editor-sdk';
import { AppManifest } from '@wix/app-manifest-builder';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { onEditorReady } from './editor-app/onEditorReady';

export function editorReady(
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> {
  return onEditorReady(editorSDK, appDefId, options, flowAPI);
}

export const getAppManifest = async (options): Promise<AppManifest> => {
  const { appManifestBuilder, ...appData } = options;
  const baseManifest = await fetchWidgetsStageData(options);

  const controllerId = '778ca2ab-ffa5-4b98-b2e3-8727de469c86-mbv14';
  const manifest = appManifestBuilder.withJsonManifest(baseManifest).build();

  manifest.controllersStageData[
    controllerId
  ].default.gfpp.mobile.iconButtons.add = { behavior: 'HIDE' };

  return manifest;
};

// export const exports = (editorSDK) => ({
//   editor: {
//     getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
//       const preset = await editorSDK.application.appStudioWidgets.getPreset(
//         '',
//         {
//           componentRef: widgetRef,
//         },
//       );
//       return `externalPreset-${preset.style}`;
//     },
//   },
// });
export const exports = (editorSDK) => ({
  editor: {
    getSelectedWidgetDesignPresetId: async ({widgetRef}) => {
      return (
        await editorSDK.application.appStudioWidgets.getPreset('', {
          componentRef: widgetRef,
        })
      ).style
    },
  },
})
